import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—May 2007" />
	<Old2005BlogPage>
		<h2>May 2007</h2>




		<h3 className="blogdate">
		Thursday, May 10, 2007
		</h3>






		<h4 className="blogitemtitle">
		Saving versus borrowing
		</h4>





			<div className="blogitembody">
			Having experienced both scenarios, I'll say that I prefer saving first and then enjoying the spending, rather than enjoying the expenditure of borrowed money and then having to recover the money.  The joy of the initial expenditure doesn't last throughout the process of recovering the money! But if you save first, then you have that "light at the end of the tunnel", you can "keep your eye on the prize", and then you can enjoy the expenditure and afterwards there is no yucky, depressy, I'm-in-debt feeling.  Think about it!<br /><br />Regardless, I'm lucky I have a lot of credit available to me, and suck as it does, I am repaying as quickly as I can.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 2:25 PM &nbsp;
			</div>




		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
